.DraftEditor-root,
.public-DraftEditor-block {
    position: relative;
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
    height: inherit;
    text-align: initial;
}

.DraftEditor-editorContainer {
    background-color: rgba(255, 255, 255, 0);
    border-left: 0.1px solid transparent;
    position: relative;
    z-index: 1;
}

.DraftEditor-alignLeft {
    .public-DraftStyleDefault-block {
        text-align: left;
    }
    .public-DraftEditorPlaceholder-root {
        left: 0;
        text-align: left;
    }
}

.DraftEditor-alignCenter {
    .public-DraftStyleDefault-block {
        text-align: center;
    }
    .public-DraftEditorPlaceholder-root {
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }
}

.DraftEditor-alignRight {
    .public-DraftStyleDefault-block {
        text-align: right;
    }
    .public-DraftEditorPlaceholder-root {
        right: 0;
        text-align: right;
    }
}

.public-DraftEditorPlaceholder-root {
    color: #9197a3;
    position: absolute;
    z-index: 0;
}

.public-DraftEditorPlaceholder-hasFocus {
    color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
    display: none;
}

.public-DraftStyleDefault-block {
    position: relative;
    white-space: pre-wrap;
}

.public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
}

.public-DraftStyleDefault-rtl {
    direction: rtl;
    text-align: right;
}

.public-DraftStyleDefault-listLTR {
    direction: ltr;
}

.public-DraftStyleDefault-listRTL {
    direction: rtl;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
    margin: 16px 0;
    padding: 0;
}

.public-DraftStyleDefault-depth0 {
    &.public-DraftStyleDefault-listLTR {
        margin-left: 1.5em;
    }
    &.public-DraftStyleDefault-listRTL {
        margin-right: 1.5em;
    }
}

.public-DraftStyleDefault-depth1 {
    &.public-DraftStyleDefault-listLTR {
        margin-left: 3em;
    }
    &.public-DraftStyleDefault-listRTL {
        margin-right: 3em;
    }
}

.public-DraftStyleDefault-depth2 {
    &.public-DraftStyleDefault-listLTR {
        margin-left: 4.5em;
    }
    &.public-DraftStyleDefault-listRTL {
        margin-right: 4.5em;
    }
}

.public-DraftStyleDefault-depth3 {
    &.public-DraftStyleDefault-listLTR {
        margin-left: 6em;
    }
    &.public-DraftStyleDefault-listRTL {
        margin-right: 6em;
    }
}

.public-DraftStyleDefault-depth4 {
    &.public-DraftStyleDefault-listLTR {
        margin-left: 7.5em;
    }
    &.public-DraftStyleDefault-listRTL {
        margin-right: 7.5em;
    }
}

.public-DraftStyleDefault-unorderedListItem {
    list-style-type: square;
    position: relative;
    &.public-DraftStyleDefault-depth0 {
        list-style-type: disc;
    }
    &.public-DraftStyleDefault-depth1 {
        list-style-type: circle;
    }
}

.public-DraftStyleDefault-orderedListItem {
    list-style-type: none;
    position: relative;
    &.public-DraftStyleDefault-listLTR:before {
        left: -36px;
        position: absolute;
        text-align: right;
        width: 30px;
    }
    &.public-DraftStyleDefault-listRTL:before {
        position: absolute;
        right: -36px;
        text-align: left;
        width: 30px;
    }
    &:before {
        content: counter(ol0) '. ';
        counter-increment: ol0;
    }
    &.public-DraftStyleDefault-depth1:before {
        content: counter(ol1) '. ';
        counter-increment: ol1;
    }
    &.public-DraftStyleDefault-depth2:before {
        content: counter(ol2) '. ';
        counter-increment: ol2;
    }
    &.public-DraftStyleDefault-depth3:before {
        content: counter(ol3) '. ';
        counter-increment: ol3;
    }
    &.public-DraftStyleDefault-depth4:before {
        content: counter(ol4) '. ';
        counter-increment: ol4;
    }
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
    counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
    counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
    counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
    counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
    counter-reset: ol4;
}
