/**  =====================
      Chart css start
==========================  **/
.nvtooltip {
    position: fixed !important;
}
.peity-chart {
    + .peity {
        width: 100%;
        height: 250px;
    }
}
/* Radial Chart Start */
$unhalf: 90, 108, 126, 144, 162, 180, 198, 216, 234, 252, 270, 288, 306, 324, 342, 360, 378, 396, 414, 432, 450;
$dt: 0;
$flg: 1;

.radial-bar {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    background-color: transparent;
    margin-bottom: 20px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 80px;
    height: 80px;
    font-size: 18px;
    background-clip: content-box;

    &:after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        text-align: center;
        font-weight: 500;
        color: #455a64;
        width: 56px;
        height: 56px;
        margin-left: 12px;
        margin-top: 12px;
        line-height: 56px;
        content: attr(data-label);
        background-color: #fff;
        z-index: 55;
    }

    > img {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        text-align: center;
        font-weight: 500;
        color: #455a64;
        width: 56px;
        height: 56px;
        margin-left: 12px;
        margin-top: 12px;
        line-height: 56px;
    }

    > img {
        z-index: 102;
    }
    @while $dt<=100 {
        &.radial-bar-#{$dt} {
            @if ($dt < 50) {
                background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent),
                    linear-gradient(#{nth($unhalf, $flg)}deg, $success-color 50%, #d6d6d6 50%, #d6d6d6);
            } @else {
                background-image: linear-gradient(#{nth($unhalf, $flg)}deg, $success-color 50%, transparent 50%, transparent),
                    linear-gradient(270deg, $success-color 50%, #d6d6d6 50%, #d6d6d6);
            }
        }
        $dt: $dt + 5;
        $flg: $flg + 1;
    }
    @each $value in $color-bt-name {
        $i: index($color-bt-name, $value);

        &.radial-bar-#{$value} {
            @while $dt<=100 {
                &.radial-bar-#{$dt} {
                    @if ($dt < 50) {
                        background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent),
                            linear-gradient(#{nth($unhalf, $flg)}deg, nth($color-bt-color, $i) 50%, #d6d6d6 50%, #d6d6d6);
                    } @else {
                        background-image: linear-gradient(
                                #{nth($unhalf, $flg)}deg,
                                nth($color-bt-color, $i) 50%,
                                transparent 50%,
                                transparent
                            ),
                            linear-gradient(270deg, nth($color-bt-color, $i) 50%, #d6d6d6 50%, #d6d6d6);
                    }
                }
                $dt: $dt + 5;
                $flg: $flg + 1;
            }
        }
    }
}
.radial-bar-lg {
    width: 100px;
    height: 100px;
    font-size: 20px;

    > img,
    &:after {
        width: 70px;
        height: 70px;
        margin-left: 15px;
        margin-top: 15px;
        line-height: 70px;
    }
}

.radial-bar-sm {
    width: 60px;
    height: 60px;
    font-size: 12px;

    > img,
    &:after {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        margin-top: 10px;
        line-height: 42px;
    }
}

.radial-bar-xs {
    width: 35px;
    height: 35px;
    font-size: 10px;

    > img,
    &:after {
        width: 25px;
        height: 25px;
        margin-left: 5.5px;
        margin-top: 4.5px;
        line-height: 25px;
    }
}
/* Radial Chart End */
/**====== Chart css end ======**/
