.sw-main {
    .Loki {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        border-radius: 0.25rem !important;
        .sw-container {
            display: block;
            margin: 0;
            padding: 0;
            position: relative;
        }
        .step-content {
            position: relative;
            margin: 0;
        }
        .sw-toolbar {
            margin-left: 0;
        }
    }
}

.sw-theme-default {
    .Loki {
        .sw-container {
            min-height: 250px;
        }
        .step-content {
            border: 0 solid #d4d4d4;
            background-color: #fff;
            text-align: left;
        }
        .sw-toolbar {
            border-radius: 0 !important;
            padding: 10px;
            margin-bottom: 0 !important;
        }
        .sw-toolbar-top {
            border-bottom-color: #ddd !important;
        }
        > ul.step-anchor > li {
            position: relative;
            margin-right: 2px;
            > a {
                border: none !important;
                color: #bbb;
                text-decoration: none;
                outline-style: none;
                background: 0 0 !important;
                border: none !important;
                cursor: not-allowed;
                &:hover {
                    border: none !important;
                    color: #bbb;
                    text-decoration: none;
                    outline-style: none;
                    background: 0 0 !important;
                    border: none !important;
                    cursor: not-allowed;
                }
            }
            &.clickable > a:hover {
                color: #4285f4 !important;
                background: 0 0 !important;
                cursor: pointer;
            }
            > a::after {
                content: '';
                background: #4285f4;
                height: 2px;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                -webkit-transition: all 250ms ease 0s;
                transition: all 250ms ease 0s;
                -webkit-transform: scale(0);
                -ms-transform: scale(0);
                transform: scale(0);
            }
            &.active > a {
                border: none !important;
                color: #4285f4;
                background: 0 0 !important;
                cursor: pointer;
                > h6 {
                    border: none !important;
                    color: #4285f4;
                    background: 0 0 !important;
                    cursor: pointer;
                }
                &::after {
                    -webkit-transform: scale(1);
                    -ms-transform: scale(1);
                    transform: scale(1);
                }
            }
            &.done > a {
                border: none !important;
                color: #000 !important;
                background: 0 0 !important;
                cursor: pointer;
                &::after {
                    background: #5cb85c;
                    -webkit-transform: scale(1);
                    -ms-transform: scale(1);
                    transform: scale(1);
                }
            }
            &.danger > a {
                border: none !important;
                color: #d9534f !important;
                cursor: pointer;
                &::after {
                    background: #d9534f;
                    border-left-color: #f8d7da;
                    -webkit-transform: scale(1);
                    -ms-transform: scale(1);
                    transform: scale(1);
                }
            }
            &.disabled > a {
                color: #eee !important;
                cursor: not-allowed;
                &:hover {
                    color: #eee !important;
                    cursor: not-allowed;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .sw-theme-default > .nav-tabs > li {
        float: none !important;
    }
}

.sw-loading {
    &::after {
        position: absolute;
        display: block;
        opacity: 1;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.7);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        z-index: 2;
    }
    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #3498db;
        width: 80px;
        height: 80px;
        margin-top: -40px;
        margin-left: -40px;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
