@import 'variables';
@import 'steps';
@import 'actions';

@import 'smart-main';
@import 'smart-arrows';
@import 'smart-circles';
@import 'samrt-dots';

.Loki {
    margin: 0 auto;
}
